// Helpers
import { api } from 'src/utils/api';
import { toast } from 'react-toastify';

/**
 * Get initialize data
 * @return {Promise}
 */
export const fetchClassroomData = async (actions, courseId, user) => {
  try {
    const response = await api({
      method: 'GET',
      url: 'initialize/c/list',
      params: { userId: user.id, courseId },
    });

    // 1. Initialize user
    actions.logUserIn(user);

    // 2. Initialize the Redux store
    actions.setInitializeData(response.data);

    return await Promise.resolve(response.data.messages);
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    if (error?.response?.status && [401, 403, 404].includes(error.response.status)) {
      toast('Erreur lors du chargement de la salle de classe', { type: 'error' });
      window.location.href = '/';
    }
  }

  return Promise.resolve({});
};
