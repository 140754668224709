/*
 * Package Import
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NewWindow from 'react-new-window';

/*
 * Local Import
 */
import Tooltip from 'src/components/Tooltip/container';
import Unread from 'src/components/Chats/Channel/Unread';
import Introduction from 'src/components/Chats/Introduction';

/*
 * Context
 */
import { MessageContext } from 'src/context/Message';
import { UserContext } from 'src/context/User';

// Helpers
import CHATS from 'src/constants/chats';
import { setFocus } from 'src/utils';
import { isEnter, isSpace, isLeft, isRight } from 'src/utils/events';
import { fetchClassroomData } from 'src/components/Classroom/utils';

// Data
import tabs from './data';

// Style
import * as S from './style';

/**
 * Code
 */
const { TAB_EXTERNAL_WINDOW } = CHATS;

/*
 * Component
 */
const Chats = ({ actions, currentMainChatView, popoutChat, courseId, isPopout, unreadPolls }) => {
  /**
   * Context
   */
  const { initializeData } = useContext(MessageContext);
  const { user } = useContext(UserContext);

  /**
   * Get the current view index
   * @param  {String} id
   * @return  {Number} index
   */
  const getIndex = (id) => Object.keys(tabs).findIndex((tab) => tabs[tab].id === id);

  /**
   * Set the new current view by adding the element ID
   * @param  {Event} evt
   */
  const onClick = (evt) => {
    actions.changeMainChatView(evt.currentTarget.id);
    setFocus(evt.currentTarget.id);
  };

  /**
   * Set the new current view /w keyboard
   * @param  {Event} evt
   */
  const onKeyDown = (evt) => {
    const allTabs = Object.keys(tabs);

    if (isSpace(evt) || isEnter(evt, false)) {
      onClick(evt);
    }
    else if (isRight(evt)) {
      const currentIndex = getIndex(evt.currentTarget.id);
      let selected;
      if (currentIndex + 1 === allTabs.length) {
        selected = allTabs[0];
      }
      else {
        selected = allTabs[currentIndex + 1];
      }
      setFocus(selected);
    }
    else if (isLeft(evt)) {
      const currentIndex = getIndex(evt.currentTarget.id);
      let selected;
      if (currentIndex === 0) {
        selected = allTabs[allTabs.length - 1];
      }
      else {
        selected = allTabs[currentIndex - 1];
      }
      setFocus(selected);
    }
  };

  /**
   * Get the Component to display
   * @param  {String} view
   * @return {ReactElement}
   */
  const getComponent = (view) => {
    const Component = tabs[view].component;
    return <Component />;
  };

  /**
   * Open the chat in an external window
   */
  const handleExtWindow = () => {
    actions.setPopoutChat(true);
  };

  /**
   * Open the chat in an external window w/ keyboard
   */
  const handleExtKeyboard = (evt) => {
    if (isSpace(evt) || isEnter(evt, false)) {
      handleExtWindow();
    }
  };

  /**
   * Close the external window
   */
  const closeExtWindow = () => {
    fetchClassroomData(actions, courseId, user).then((messages) => initializeData(messages));
    actions.setPopoutChat(false);
  };

  /**
   * cta
   */
  const cta = {
    display: true,
    onClick: closeExtWindow,
    label: 'Ramener le chat',
    Component: null,
  };

  /*
   * Render
   */
  return popoutChat ? (
    <S.Container>
      <NewWindow
        onUnload={closeExtWindow}
        title="Chat"
        copyStyles
        features={{
          width: '520px',
          height: '902px',
        }}
      >
        <iframe
          title="Chat"
          src={`${document.location.origin}/classroom/chat/${courseId}/`}
          height="100%"
          width="100%"
        />
      </NewWindow>
      <Introduction
        key="chat-detached"
        icon={S.IconMagic}
        title="Vous avez ouvert le Chat dans un onglet séparé"
        paragraph="Vous pouvez le faire revenir à tout moment en cliquant sur le bouton ci-dessous"
        cta={cta}
      />
    </S.Container>
  ) : (
    <S.Container>
      <S.TitleSrOnly id="chatsPresentation">Interactions avec la classe</S.TitleSrOnly>
      {!isPopout && (
        <S.ExtWindowTab
          key={TAB_EXTERNAL_WINDOW}
          id={TAB_EXTERNAL_WINDOW}
          role="button"
          aria-label="Ouvrir le chat dans une nouvelle fenêtre"
          tabIndex="0"
          onClick={handleExtWindow}
          onKeyDown={handleExtKeyboard}
        >
          <Tooltip content="Ouvrir le chat dans une nouvelle fenêtre">
            <S.IconExternalWindow />
          </Tooltip>
        </S.ExtWindowTab>
      )}

      {/* Buttons */}
      <S.Tabs role="tablist" aria-label="Interactions avec les participants">
        {Object.keys(tabs).map((tab) => (
          <S.Tab
            key={tabs[tab].id}
            id={tabs[tab].id}
            onClick={onClick}
            onKeyDown={onKeyDown}
            // a11y
            role="tab"
            aria-selected={currentMainChatView === tabs[tab].id}
            aria-controls={tabs[tab].id}
            tabIndex={currentMainChatView !== tabs[tab].id ? '-1' : '0'}
          >
            <S.SubTitle isActive={currentMainChatView === tabs[tab].id}>
              {tabs[tab].label}
            </S.SubTitle>

            {/* Unread polls */}
            {tab === CHATS.TAB_SURVEY && <Unread unread={unreadPolls} />}
          </S.Tab>
        ))}
      </S.Tabs>

      {/* Display Component by the current view */}
      <>{currentMainChatView && getComponent(currentMainChatView)}</>
    </S.Container>
  );
};

/*
 * PropTypes
 */
Chats.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  currentMainChatView: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  popoutChat: PropTypes.bool.isRequired,
  isPopout: PropTypes.bool,
  unreadPolls: PropTypes.number,
};

Chats.defaultProps = {
  isPopout: false,
  unreadPolls: 0,
};

/*
 * Export
 */
export default Chats;
