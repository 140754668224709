/*
 * Package Import
 */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

/*
 * Local Import
 */
import Notifications from 'src/components/TopBar/Notifications/container';
import Signature from 'src/components/TopBar/Signature/container';
import Topbar from 'src/components/TopBar/container';
import Course from 'src/components/Course/container';
import Chats from 'src/components/Chats/container';
import Users from 'src/components/Users';
import AwayChoice from 'src/components/TopBar/Menu/AwayChoice/container';
import Away from 'src/components/Away/container';
import HotkeysListener from 'src/components/Hotkeys/container';
import NotificationReader from 'src/components/NotificationsReader/container';
import PositionedSnackbar from 'src/components/Snackbar/container';
import Feedback from 'src/components/TopBar/Menu/Feedback/container';
import Shortcuts from 'src/components/TopBar/Menu/Shortcuts/container';
import SigninSheet from 'src/components/TopBar/Menu/SigninSheet/container';
import ResetSignature from 'src/components/TopBar/Signature/ResetSignature/container';
import SignFollowing from 'src/components/TopBar/Menu/SignFollowing/container';
import StudentTracking from 'src/components/Tracking/container';
import Loader from 'src/components/Loader';
import { useWebsocket } from 'src/hooks';
import { getChatSize } from 'src/store/selectors/settings';

// Context
import { MessageContext } from 'src/context/Message';
import { UserContext } from 'src/context/User';

// Constants
import { getClassroomTitle } from 'src/constants/titles';

// Helpers
import { trackEvent } from 'src/utils/tracking';
import { fetchClassroomData } from 'src/components/Classroom/utils';
import { useAction, useChat, useMessage, useReaction, useSurvey } from './hooks';

// Style
import * as S from './style';

/*
 * Component
 */
const Classroom = ({
  actions,
  courseTitle,
  readerIsOpen,
  chatOpened,
  usersOpened,
  menuNotificationsIsOpen,
  menuSignatureIsOpen,
  resetSignaturesIsOpen,
}) => {
  /*
   * Hooks
   */
  const { course } = useParams();
  const { pathname } = useLocation();
  const chatSize = useSelector(getChatSize);
  const webSocket = useWebsocket(course);

  // Socket events listeners
  useAction(webSocket);
  useChat(webSocket);
  useMessage(webSocket);
  useReaction(webSocket);
  useSurvey(webSocket);

  /*
   * Context
   */
  const { initializeData } = useContext(MessageContext);
  const { user } = useContext(UserContext);

  /**
   * Refs
   */
  const isClassroomFetching = useRef(true);

  /**
   * Set focus on the first element
   * @return {void}
   */
  const setFocusOnFirstElement = (aside = 'users') => {
    setTimeout(() => {
      let indexAside = 0;
      if (aside === 'users') indexAside = 1;
      if (aside === 'chat') indexAside = 2;

      const asides = document.querySelectorAll("div[role='region']");
      const currentAside = asides[indexAside];

      const focusables = currentAside.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );

      const nodeToFocus = focusables[0];
      nodeToFocus.focus();
    }, 300);
  };

  const handleDrawerLeft = () => {
    actions.toggleUsers();
    if (!usersOpened) {
      setFocusOnFirstElement('users');
    }
  };

  const handleDrawerRight = () => {
    actions.toggleChat();
    if (!chatOpened) {
      setFocusOnFirstElement('chat');
    }
  };

  /*
   * LifeCycles
   */
  useEffect(() => {
    if (!isClassroomFetching.current) {
      // Page Title
      if (courseTitle) {
        const CLASSROOM_TITLE = getClassroomTitle(courseTitle);
        document.title = CLASSROOM_TITLE;

        // Mixpanel tracking event
        trackEvent('Page Viewed', {
          title: CLASSROOM_TITLE,
          url: pathname,
        });
      }
    }

    // Handle browser back button to handle course list return
    // and course connected users number
    window.onpopstate = () => {
      window.location = '/';
    };

    window.onbeforeunload = () => {
      // Check if user leaves course by app button or by browser event (back, close tab, ...)
      if (window.document.activeElement.tagName === 'BUTTON') {
        actions.courseLeft('Exit button');
      }
      else {
        actions.courseLeft('Browser event');
      }
    };
  }, [course, courseTitle]);

  useEffect(() => {
    // Init Classroom data
    if (isClassroomFetching.current && course) {
      fetchClassroomData(actions, course, user).then((messages) => {
        initializeData(messages);
        isClassroomFetching.current = false;
      });
    }
  }, [course]);

  /*
   * Render
   */
  if (isClassroomFetching.current) {
    return <Loader overlay size={48} />;
  }

  return (
    <S.Main
      role="main"
      id="main"
      leftOpened={usersOpened}
      rightOpened={chatOpened}
      chatSize={chatSize}
    >
      {/* Top */}
      <S.MainTitleSrOnly id="courseName">{courseTitle}</S.MainTitleSrOnly>
      <S.AppBar role="region" aria-labelledby="courseName" id="workspace-navigation">
        <Topbar
          drawerLeftOpen={usersOpened}
          handleDrawerLeft={handleDrawerLeft}
          drawerRightOpen={chatOpened}
          handleDrawerRight={handleDrawerRight}
          courseTitle={courseTitle}
        />

        {/* Notification Panel */}
        {menuNotificationsIsOpen && <Notifications chatPanelOpened={chatOpened} />}
        {menuSignatureIsOpen && <Signature chatPanelOpened={chatOpened} />}
      </S.AppBar>

      {/* Left */}
      <S.Drawer
        anchor="left"
        role="region"
        aria-hidden={!usersOpened}
        aria-label="Utilisateurs"
        aria-labelledby="participants"
        id="workspace-drawer-left"
        leftOpened={usersOpened}
      >
        <Users />
      </S.Drawer>

      {/* Center */}
      <S.Center
        role="region"
        aria-label="Fenêtre de cours"
        aria-labelledby="coursePresentation"
        id="workspace-drawer-main"
      >
        <Course />
        {readerIsOpen && <NotificationReader rightOpened={chatOpened} />}
        <PositionedSnackbar />
      </S.Center>

      {/* Right */}
      <S.Drawer
        anchor="right"
        role="region"
        aria-hidden={!chatOpened}
        aria-label="Interactions avec les participants"
        id="workspace-drawer-right"
        rightOpened={chatOpened}
        chatSize={chatSize}
      >
        <Chats />
      </S.Drawer>
      {/* Overlay */}
      <AwayChoice />
      <Away />
      <Feedback />
      <Shortcuts />
      <SigninSheet />
      {resetSignaturesIsOpen && <ResetSignature />}
      <SignFollowing />
      <HotkeysListener />
      <StudentTracking />

      {user.role !== 'basic' && <ToastContainer position="top-center" />}
    </S.Main>
  );
};

/*
 * PropTypes
 */
Classroom.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
  readerIsOpen: PropTypes.bool.isRequired,
  menuNotificationsIsOpen: PropTypes.bool.isRequired,
  menuSignatureIsOpen: PropTypes.bool.isRequired,
  courseTitle: PropTypes.string,
  chatOpened: PropTypes.bool.isRequired,
  usersOpened: PropTypes.bool.isRequired,
  resetSignaturesIsOpen: PropTypes.bool.isRequired,
};

Classroom.defaultProps = {
  courseTitle: null,
};

/*
 * Export
 */
export default Classroom;
