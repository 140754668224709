/*
 * Package Import
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/*
 * Local Import
 */
import Chats from 'src/components/Chats';
import { changeMainChatView, logUserIn, setInitializeData, setPopoutChat } from 'src/store/actions';

/*
 * Code
 */
const mapStateToProps = (state) => ({
  currentMainChatView: state.chats.currentMainChatView,
  courseId: state.course.courseId,
  popoutChat: state.settings.popout.popoutChat,
  unreadPolls: state.chats.unreadPolls ?? 0,
});

// Actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logUserIn,
      setInitializeData,
      changeMainChatView,
      setPopoutChat,
    },
    dispatch,
  ),
});
/*
 * Export
 */
export default connect(mapStateToProps, mapDispatchToProps)(Chats);
