/*
 * Package Import
 */
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';

/*
 * Local Import
 */
import Loader from 'src/components/Loader';
import Chats from 'src/components/Chats/container';
import HotkeysListener from 'src/components/Hotkeys/container';
import { useAction, useChat, useMessage, useReaction, useSurvey } from 'src/components/Classroom/hooks';
import { useWebsocket } from 'src/hooks';
import { fetchClassroomData } from 'src/components/Classroom/utils';

// Context
import { MessageContext } from 'src/context/Message';
import { UserContext } from 'src/context/User';

// Helpers
import { trackEvent } from 'src/utils/tracking';

// Constants
import { CLASSROOM_EXTERNAL_TITLE } from 'src/constants/titles';

// Style
import * as S from 'src/components/Classroom/style';

/*
 * Component
 */
const ExternalChat = ({ actions }) => {
  /*
   * Hooks
   */
  const { course } = useParams();
  const { pathname } = useLocation();
  const webSocket = useWebsocket(course);

  // Socket events listeners
  useAction(webSocket);
  useChat(webSocket);
  useMessage(webSocket);
  useReaction(webSocket);
  useSurvey(webSocket);

  /*
   * Context
   */
  const { initializeData } = useContext(MessageContext);
  const { user } = useContext(UserContext);

  /*
   * State
   */
  const [isLoading, setLoading] = useState(
    /** @type {Boolean} */
    true,
  );

  /*
   * LifeCycles
   */
  useEffect(() => {
    document.title = CLASSROOM_EXTERNAL_TITLE;

    // Mixpanel tracking event
    trackEvent('Page Viewed', {
      title: CLASSROOM_EXTERNAL_TITLE,
      url: pathname,
    });
  }, []);

  useEffect(() => {
    // Initialize chat
    if (user && user.id) {
      fetchClassroomData(actions, course, user).then((messages) => {
        initializeData(messages);
        setLoading(false);
      });
    }
  }, [course, user]);

  /*
   * Render
   */
  if (isLoading) {
    return <Loader overlay size={48} />;
  }

  return (
    <S.Main role="main" id="main">
      <S.Popout>
        <HotkeysListener />
        <Chats isPopout />
      </S.Popout>
    </S.Main>
  );
};

/*
 * PropTypes
 */
ExternalChat.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
};

/*
 * Export
 */
export default ExternalChat;
